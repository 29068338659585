<template>
    <div class="py-32 bg-green-600 w-full ">
        <div class="w-4/5 lg:w-3/5 m-auto ">
            <p class="text-black font-bold tracking-[.25em]">HELLO THERE</p>
            <h1 class="text-white text-5xl lg:text-7xl font-bold py-1">We Are Glint</h1>
            <div class="my-10 border-b w-3/6 border-gray-300 mx-auto"></div>
            <p class="text-black text-lg lg:text-2xl lg:leading-loose">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</p>
        </div>
        <div class="grid sm:grid-cols-2 grid-cols-1 lg:grid-cols-4 gap-4 w-4/5  mx-auto text-7xl font-bold mt-12 text-white">
            <div class=""><div class="border-b sm:border-b-0 sm:border-r mx-16 sm:mx-0 ">127 <div class="text-xl text-black py-3">Awards Received</div></div></div>
            <div class=""><div class="border-b sm:border-b-0 sm:border-r-0 lg:border-r mx-16 sm:mx-0">1505 <div class="text-xl text-black py-3">Cups of Coffee</div></div></div>
            <div class=""><div class="border-b sm:border-b-0 sm:border-r mx-16 sm:mx-0">109<div class="text-xl text-black py-3">Projects Completed</div></div></div>
            <div class=""><div class="">102 <div class="text-xl text-black py-3">Happy Clients</div></div></div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
