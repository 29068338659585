<template lang="">
    <div>
           <div class="py-32  w-full ">
        <div class="w-4/5 lg:w-3/5 m-auto ">
            <p class="text-green-600 font-bold tracking-[.25em]">What We Do</p>
            <h1 class="text-3xl md:text-4xl text-black lg:text-6xl font-bold py-1">We’ve got everything you need to launch and grow your business</h1>
            <div class="my-10 border-b w-3/6 border-gray-300 mx-auto"></div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 w-4/5 lg:w-3/5 m-auto gap-x-12 lg:gap-x-36 pt-7">
            <div class="md:flex justify-center mt-4">
                <div class="text-green-600 text-5xl"><i class="fas fa-brush"></i></div>
                <div class="md:pl-10 md:text-left">
                    <h1 class="text-2xl mb-6 text-black font-bold">Brand Identity</h1>
                    <p class="text-gray-400">Nemo cupiditate ab quibusdam quaerat impedit magni. Earum suscipit ipsum laudantium. Quo delectus est. Maiores voluptas ab sit natus veritatis ut. Debitis nulla cumque veritatis. Sunt suscipit voluptas ipsa in tempora esse soluta sint.</p>
                </div>
            </div>
            <div class="md:flex justify-center mt-4">
                <div class="text-green-600 text-5xl"><i class="far fa-object-group"></i></div>
                <div class="md:pl-10 md:text-left ">
                    <h1 class="text-2xl mb-6 text-black font-bold">Illustration</h1>
                    <p class="text-gray-400">Nemo cupiditate ab quibusdam quaerat impedit magni. Earum suscipit ipsum laudantium. Quo delectus est. Maiores voluptas ab sit natus veritatis ut. Debitis nulla cumque veritatis. Sunt suscipit voluptas ipsa in tempora esse soluta sint.</p>
                </div>
            </div>
           
        </div>
        </div> 
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>